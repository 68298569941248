<template>
  <div>
    <el-row>
      <el-col class="title-admin">
        {{mainTitle}}
      </el-col>
    </el-row>
      <div v-for="(content,index) in contents" :key="index">
        <el-row class="primaryTitle" v-if="content.type === 'primaryTitle'">
          <!--熊猫图标-->
          <el-col :span="2">
            <img class="icon" :src="iconImage" alt="">
          </el-col>
          <!--title-->
          <el-col :span="22">
            <p class="title">{{content.text}}</p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'text'">
          <el-col>
            <p class="demo-text" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'button'">
          <el-col>
            <div class="vic_button" >
                <button @click="openLink(content.text)">Download the Prospectus</button>
            </div>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryBlack'">
          <el-col class="secondaryTitle">
            <p class="secondaryBlack" v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'secondaryTitle'">
          <el-col class="secondaryTitle">
            <p v-html="content.text"></p>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'noList'">
          <el-col>
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract'">
          <el-col class="retract">
            <li class="noList">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'image'">
          <el-col>
            <div class="contact_image">
              <img :src="content.src" style="max-width: 50%; height: auto;">
            </div>
          </el-col>
        </el-row>
        <el-row v-else-if="content.type === 'noListBlack'">
          <el-col>
            <li class="noList black-text">
              <div  v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retractBlack'">
          <el-col class="retract">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2'">
          <el-col class="retract2">
            <li class="noList">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'retract2Black'">
          <el-col class="retract2">
            <li class="noList black-text">
              <div v-html="content.text"></div>
            </li>
          </el-col>
        </el-row>

        <el-row v-else-if="content.type === 'textBlue'">
          <el-col>
            <p class="textBlue" v-html="content.text"></p>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
  name: "Our Sponsors",
  data() {
    return {
      iconImage: require("@/assets/content/bullet_point.png"),
      mainTitle: 'Our Sponsors',
      contents:[
        {
          type:'primaryTitle',
          text:'Platinum Sponsor',
        },
        {
          type:'image',
          text:'<img src="@/assets/sponsors/sponsosr_ant.png">',
          src: require("@/assets/sponsors/sponsosr_ant.png"),
        },
        {
          type:'text',
          text:'Ant Group traces its roots back to Alipay, which was established in 2004. Today, we are a world-leading internet open platform.',
        },
        {
          type:'text',
          text:'Through technological innovation, we support our partners to enable inclusive, convenient digital life and digital financial services for consumers and SMEs (small and micro-sized enterprises). We have also continuously opened up our technology products and services to support the digital transformation of businesses and facilitate digital collaboration across industries and geographies. Our collaboration with global partners helps merchants better connect with consumers around the world.<br><br><br>',
        },
        {
          type:'image',
          text:'<img src="@/assets/sponsors/sponsosr_monash.png">',
          src: require("@/assets/sponsors/sponsosr_monash.png"),
        },
        {
          type:'text',
          text:'Monash Faculty of IT ranks #1 in Australia for Computer Science (Times Higher Education 2024) and #34 globally for Data Science and AI (QS World University Rankings 2024). We play a pivotal role in international collaborations, leading over 200 transformative social good projects with partners addressing the key challenges of the age. Out of Australia’s prestigious Group of Eight universities, only Monash has a faculty dedicated to IT.  Adopting a global outlook, we are a powerful community of the best minds – skilled educators who inspire and empower, and talented researchers who innovate and disrupt.<br><br><br>',
        },
        {
          type:'image',
          text:'<img src="@/assets/sponsors/sponsor_nec.png">',
          src: require("@/assets/sponsors/sponsor_nec.png"),
        },
        // NEC Corporation, established in 1899, is a Japanese multinational conglomerate company headquartered in Tokyo, Japan. It is a leader in IT services, telecommunications equipment, and a major player in the global market for telecommunications infrastructure. The company has made significant contributions to various industries, including telecommunications, artificial intelligence, robotics, and environmental solutions.

        // NEC is known for its innovative products and solutions, such as the world's first commercial laser discs, advanced computer systems, and biometric identification technology. The company has also been a pioneer in developing artificial intelligence and has launched several initiatives to apply AI to improve business operations, create new services, and address global challenges such as climate change and aging population.

        // NEC's commitment to sustainability and social responsibility has earned it recognition as a responsible corporate citizen. The company has set ambitious goals to reduce its carbon footprint and promote diversity and inclusion. NEC's diverse portfolio of products and services has made it a trusted partner for businesses and governments around the world.
        {
          type:'text',
          text:'NEC Corporation, established in 1899, is a Japanese multinational conglomerate company headquartered in Tokyo, Japan. It is a leader in IT services, telecommunications equipment, and a major player in the global market for telecommunications infrastructure. The company has made significant contributions to various industries, including telecommunications, artificial intelligence, robotics, and environmental solutions.<br><br>NEC is known for its innovative products and solutions, such as the world\'s first commercial laser discs, advanced computer systems, and biometric identification technology. The company has also been a pioneer in developing artificial intelligence and has launched several initiatives to apply AI to improve business operations, create new services, and address global challenges such as climate change and aging population.<br><br>NEC\'s commitment to sustainability and social responsibility has earned it recognition as a responsible corporate citizen. The company has set ambitious goals to reduce its carbon footprint and promote diversity and inclusion. NEC\'s diverse portfolio of products and services has made it a trusted partner for businesses and governments around the world.<br><br><br>',
        },
        {
          type:'primaryTitle',
          text:'Silver Sponsor',
        },
        {
          type:'image',
          text:'<img src="@/assets/sponsors/sponsosr_aisphere.svg" alt="diagram">',
          src: require("@/assets/sponsors/sponsosr_aisphere.svg"),
        },
        {
          type:'text',
          text:'AIsphere - Developing World-Leading AI Video Generation Large Models and Applications.',
        },
        {
          type:'text',
          text:'We are committed to developing in-depth and comprehensive visual generation capabilities by understanding the associations <u><b>among multi-modal data</b></u>, and accomplishing high-quality visual generation tasks.',
        },
        {
          type:'text',
          text:'Main Product - PixVerse',
        },
        {
          type:'text',
          text:'PixVerse is an online <u><b>AI</b></u> video generator. Users can generate accurate, consistent, and vivid videos from <u><b>text and image inputs</b></u>, combined with parameters such as style, motion and aspect ratio. Since its launch in January 2024, PixVerse has seen over <u><b>millions of</b></u> visits to the official website: <a href="https://pixverse.ai/">https://pixverse.ai/</a>. Creators worldwide have applied PixVerse in video production for films, advertisements, and animations.',
        },
        // The latest launch video of PixVerse:
        // https://x.com/PixVerse_/status/1815887287483555876

        // The shared vision of PixVerse and its global users: Why use AI to create videos?
        // https://x.com/PixVerse_/status/1794934117928436205
        {
          type: 'text',
          text: 'The latest launch video of PixVerse: <br><a href="https://x.com/PixVerse_/status/1815887287483555876">https://x.com/PixVerse_/status/1815887287483555876</a><br><br>The shared vision of PixVerse and its global users: Why use AI to create videos? <br><a href="https://x.com/PixVerse_/status/1794934117928436205">https://x.com/PixVerse_/status/1794934117928436205</a><br><br><br>',
        },
      ],
    }
  },
  methods:{
    openLink: function(link){
      window.open(link, '_blank');
    }
  },
  mounted() {
    document.title = 'ACM Multimedia 2024 Our Sponsors'
  },
}

</script>

<style scoped>
@import "../../css/responsive.css";

.vic_button{
    /* display: inline-block; */
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content:center;
    /* align-content: center; */
    align-items: center;
    /* background: #05cedd; */
    text-align: center;
    /* color: #FFFFFF; */
}

.vic_button > button{
    border: none;
    padding: 1.5rem 6rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    display: inline-block;
    font-size: 2rem;
    background: #2C9370;
    color: #FFFFFF;
    border-radius: 1.2rem; 
}

</style>